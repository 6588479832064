import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../lib/helpers";
import Layout from "../components/common/layout";
import InformationNavbar from "../components/information/information-navbar";
import AnnouncementList from "../components/information/announcement-list";
import Pagination from "../components/common/pagination";

export const query = graphql`
  query AnnouncementPageQuery($skip: Int!, $limit: Int!) {
    announcements: allSanityAnnouncement(
      skip: $skip
      limit: $limit
      sort: { fields: publishedAt, order: DESC }
      filter: { slug: { current: { ne: "null" } }, publishedAt: { ne: "null" } }
    ) {
      edges {
        node {
          id
          formattedDate: publishedAt(
            formatString: "dddd, DD MMM YYYY"
            locale: "id"
          )
          publishedAt
          title
          slug {
            current
          }
          _rawPreview
        }
      }
    }
  }
`;

const AnnouncementPage = (props) => {
  const { data } = props;

  const nodes = (data || {}).announcements
    ? mapEdgesToNodes(data.announcements)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <Layout>
      <InformationNavbar />
      {nodes && <AnnouncementList nodes={nodes} />}
      <Pagination
        humanPageNumber={props.pageContext.humanPageNumber}
        numberOfPages={props.pageContext.numberOfPages}
        previousPagePath={props.pageContext.previousPagePath}
        nextPagePath={props.pageContext.nextPagePath}
        paginationPath={props.pageContext.paginationPath}
      />
    </Layout>
  );
};

export default AnnouncementPage;
