import React from "react";
import { Link } from "gatsby";
import { format } from "date-fns";
import PortableText from "../portableText";

const AnnouncementPreview = (props) => {
  const dateSegment = format(new Date(props.node.publishedAt), "yyyy/MM");
  const path = `/pengumuman/${dateSegment}/${props.node.slug.current}`;

  return (
    <div className="pv3">
      <div className="bg-white ba b--white br3 pa2 ph5-ns">
        <span className="db f3 f2-ns display dark-gray mt4">
          {props.node.title}
        </span>
        <div className="mv3 mb4-ns flex justify-between">
          <div className="db f6 silver ttu tracked sans-serif">
            {props.node.formattedDate}
          </div>
        </div>
        <div className="serif f4 lh-copy">
          <PortableText blocks={props.node._rawPreview} />
        </div>
        <div className="pa2 flex justify-end serif h3">
          <Link
            className="gray hover-black-90 tracked ttu sans-serif f5"
            to={path}
          >
            Baca Selengkapnya
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementPreview;
